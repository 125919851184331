<template>
	<div>
		<b-card no-body>
			<b-tabs card>
				<b-tab title="Change Log" active>
					<b-card-text>
						<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
							:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
							:sort-direction="sortDirection" responsive>
							<template v-slot:cell(dateCreated)="row">{{
								getFormattedDateWithTime(row.item.dateCreated)
							}}</template>
							<template v-slot:cell(old)="row">
								<b class="numFont">{{ row.item.old ? row.item.old.total : '-' }}</b>
							</template>
							<template v-slot:cell(new)="row">
								<b class="numFont">{{ row.item.new.total }}</b>
							</template>
							<template v-slot:cell(diff)="row">
								<b :class="[
									isIncrement(row.item.old, row.item.new)
										? 'increment'
										: 'decrement',
										'numFont'
								]">
									{{ getDiffValue(row.item.old, row.item.new) }}
								</b>
							</template>
							<template v-slot:cell(reasonForUpdate)="row">{{
								row.item.new.notes
							}}</template>
						</b-table>

						<b-row>
							<b-col md="8" sm="12" class="my-1">
								<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
							</b-col>
							<b-col md="4" sm="12" class="my-1">
								<b-pagination align="right" :total-rows="totalRows" :per-page="perPage"
									v-model="currentPage" class="my-0" />
							</b-col>
						</b-row>

						<b-row class="mt-4 mb-2">
							<b-col sm="12" class="text-sm-right">
								<b-button size="sm" @click="row.toggleDetails" variant="secondary"
									v-b-tooltip.hover.top="'Hide Details'">
									<i class="icon-arrow-up"></i>
								</b-button>
							</b-col>
						</b-row>
					</b-card-text>
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
// API & Database
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Util
import { DateUtil } from '@/utils/dateutil';

export default {
	name: 'asset-pool-distribution-details-view',
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				'createdBy',
				'dateCreated',
				{ key: 'old', label: 'Previous Total' },
				{ key: 'diff', label: 'Change' },
				{ key: 'new', label: 'New Total' },
				{ key: 'reasonForUpdate', label: 'Remarks' },
			],
			currentPage: 1,
			perPage: 5,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
		};
	},
	async mounted() {
		let distributionId = this.row.item.id;
		await this.retrieveChangeLog(distributionId);
	},
	methods: {
		async retrieveChangeLog(distributionId) {
			if (distributionId) {
				let param = {
					collection: 'assetPoolDistributions',
					id: distributionId,
				};

				let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
				this.items = Object.values(results);
				this.totalRows = this.items.length;
			}
		},

		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},

		getDiffValue(oldObj, newObj) {
			let oldValue = oldObj && oldObj.total ? oldObj.total : 0;
			let newValue = newObj && newObj.total ? newObj.total : 0;

			let sign = '';
			if (oldValue < newValue) sign = '+';
			if (oldValue > newValue) sign = '-';

			return sign + Math.abs(oldValue - newValue);
		},

		isIncrement(oldObj, newObj) {
			let oldValue = oldObj && oldObj.total ? oldObj.total : 0;
			let newValue = newObj && newObj.total ? newObj.total : 0;
			return oldValue < newValue;
		},
	},
};
</script>

<style scoped>
.increment {
	color: green;
}

.decrement {
	color: red;
}
</style>
